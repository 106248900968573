<template>
  <div class="app-container">
    <eHeader
      :query="query"
      @toQuery="toQuery"
      @clear="clear"
    />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="编号"/>
      <el-table-column prop="userId" label="类型">
        <template slot-scope="scope">
          <span>{{getType(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createUser" label="创建人"/>
      <el-table-column prop="fromEmail" label="发送人">
        <template slot-scope="scope">
          <span>{{getSender(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="describe" label="描述"/>
      <el-table-column prop="describe" label="状态">
        <template slot-scope="scope">
          <span>{{getStatus(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="提交时间">
        <template slot-scope="scope">
          <div>
            {{parseTime(scope.row.updateTime)}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="getAuditFlag(scope.row)"
            @click="auditHandle(scope.row)"
            size="mini"
            type="primary"
          >审核</el-button>
          <el-button
            v-if="getLookFlag(scope.row)"
            size="mini"
            @click="lookHandle(scope.row)"
            type="danger"
          >查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <email-audit-detail
      ref="emailauditdetail"
      :is-audit="isAudit"
      :row-id="currentRowId"
    ></email-audit-detail>
    <message-audit-detail
      ref="messageauditdetail"
      :is-audit="isAudit"
      :row-id="currentRowId"
    ></message-audit-detail>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/notificationManager/massAudit/header';
import emailAuditDetail from '@/components/notificationManager/massAudit/emailAuditDetail';
import messageAuditDetail from '@/components/notificationManager/massAudit/messageAuditDetail';
export default {
  name:'massAudit',
  components: {
    eHeader,
    emailAuditDetail,
    messageAuditDetail,
  },
  mixins: [initData],
  data() {
    return {
      query:{
        createUser: '',
        selfType: '',
        status: '',
      },
      isAudit: true,
      currentRowId: 0,
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    });
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = 'crm/sysSendMailGroup'
      const sort = 'id,desc'
      this.params = {
        page: this.page,
        size: this.size,
        sort: sort,
        status: 0,
      }
     if (this.query.userInfo) {
        this.params.userInfo = this.query.userInfo;
     }
      if (this.query.templateId) {
        this.params.templateId = this.query.templateId;
      }
      if (this.query.status !== '') {
        this.params.status = this.query.status;
      }
      return true
    },
    getAuditFlag(row){
      return checkPermission(['ADMIN','SysSendMailQuartz_ALL','SysSendMailQuartz_EDIT']) && (row.status === 2);
    },
    auditHandle(row){
      this.isAudit = true;
      this.currentRowId = row.id;
      this.popDialog(row);
    },
    lookHandle(row){
      this.isAudit = false;
      this.currentRowId = row.id;
      this.popDialog(row);
    },
    popDialog(row){
      if(row.type == 1){
        this.$refs.emailauditdetail.dialog = true;
      }else {
        this.$refs.messageauditdetail.dialog = true;
      }
    },
    getType(row){
      if(row.type == 1){
        return '邮件';
      }else {
        let inner = '';
        switch (row.classification) {
          case 1:
            inner = '代办';
            break;
          case 2:
            inner = '账户';
            break;
          case 3:
            inner =  '代办';
            break;
        }
        return `站内信(${inner})`;
      }
    },
    getSender(row){
      if(row.type == 2){
        return 'Maxain 官方';
      }else {
        return row.fromEmail;
      }
    },
    getStatus(row){
      switch (row.status) {
        case 1:
          return '未提交';
        case 2:
          return '待审核';
        case 3:
          return '审核通过';
        case 4:
          return '审核拒绝';
      }
    },
    getLookFlag(row){
      return checkPermission(['ADMIN','SysSendMailGroup_ALL','SysSendMailGroup_SELECT'
      ]) && (row.status !== 2);
    },
    clear(){
      this.query= {};
      this.toQuery();
    }
  }
}
</script>

<style scoped>

</style>
